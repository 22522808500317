import { Component, Show } from "solid-js";
import { Meta } from "@solidjs/meta";

type MetaDetailsProps = {
  title?: string;
  description?: string;
  keywords?: string | string[];
};

export const MetaDetails: Component<MetaDetailsProps> = (props) => {
  const keywords = () => {
    if (!props.keywords) return undefined;
    if (props.keywords instanceof Array) return props.keywords.join(",");
    return props.keywords;
  };

  // algolia/netlify crawler will pick up the following as 'category':
  // <Meta property="article:section" content="content.." />

  return (
    <>
      <Show when={props.title}>
        <Meta property="og:title" content={props.title} />
      </Show>
      <Show when={props.description}>
        <Meta name="description" content={props.description} />
        <Meta property="og:description" content={props.description} />
      </Show>
      <Show when={keywords()}>
        <Meta name="keywords" content={keywords()} />
      </Show>
    </>
  );
};

